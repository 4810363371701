<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Debit customer account</span>
        </v-card-title>
        <v-form
          ref="form"
          method="post"
          action="/"
          lazy-validation
          @submit.prevent="debitAccount()"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="8" class="mb-2">
                  <v-autocomplete
                    v-model="form.user_id"
                    :items="users"
                    :loading="isUsersLoading"
                    :search-input.sync="users_search"
                    :filter="filterObject"
                    clearable
                    hide-details
                    item-text="name"
                    item-value="id"
                    cache-items
                    hide-no-data
                    label="Customer name"
                    placeholder="Search customer name or account number"
                    :rules="[
                      v => !!v || 'Customer name is required',
                    ]"
                  >
                    <!-- Rest of the templates related to the autocomplete -->
                  </v-autocomplete>
                </v-col>
                <v-col cols="7">
                  <v-text-field
                    v-model="form.amount"
                    label="Amount"
                    placeholder="1000"
                    :rules="[
                      v => !!v || 'required', v => v > 0 || 'The value must be greater than 0'
                    ]"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeDialog()"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
              :loading="form.busy"
            >
              Debit
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Form from 'vform'
import axios from 'axios'
import _ from 'lodash'

export default {
  props: {
    showDialog: {
      type: Boolean,
    },
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    form: new Form({
      user_id: '',
      amount: '',
    }),
    users: [],
    isUsersLoading: false,
    users_search: null,
  }),
  watch: {
    users_search(value) {
      this.getUsers(value)
    },
    user: {
      handler(value) {
        this.form.user_id = value.id
        this.users.push(value)
      },
      deep: true,
    },
    showDialog(value) {
      if (value && this.users.length === 0) {
        this.getUsers()
      }
    },
  },
  methods: {
    filterObject(item, queryText, itemText) {
      return (
        item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
                || item.account_number.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      )
    },
    closeDialog() {
      this.$emit('close')
      this.$refs.form.reset()
      this.form.account_type = 1
    },
    getUsers: _.debounce(function (value) {
      this.isUsersLoading = true
      axios
        .get(`users?searchByNameAndAccountNo=${value}`)
        .then(response => {
          this.users = response.data.data
          this.isUsersLoading = false
        })
        .catch(error => {
          this.isUsersLoading = false
          this.$notification.error(error.response.data.message)
        })
    }, 500),
    debitAccount() {
      this.form
        .post('users-account/debit')
        .then(() => {
          this.$notification.success('Account debited successfully.')
          this.$emit('close')
          this.$emit('account-debited')
          this.$refs.form.reset()
        })
        .catch(error => {
          this.$notification.error(error.response.data.message)
        })
    },
  },
}
</script>

<style lang="scss">
.v-menu__content:not(.list-style) .v-list-item {
    min-height: 48px !important;
    height: unset !important;
}
</style>
